module.exports = function () {
  "use strict";

  var ownKeys = require('reflect.ownkeys');
  var reduce = Function.bind.call(Function.call, Array.prototype.reduce);
  var isEnumerable = Function.bind.call(Function.call, Object.prototype.propertyIsEnumerable);
  var concat = Function.bind.call(Function.call, Array.prototype.concat);
  if (!Object.values) {
    Object.values = function values(O) {
      return reduce(ownKeys(O), function (v, k) {
        return concat(v, typeof k === 'string' && isEnumerable(O, k) ? [O[k]] : []);
      }, []);
    };
  }
  if (!Object.entries) {
    Object.entries = function entries(O) {
      return reduce(ownKeys(O), function (e, k) {
        return concat(e, typeof k === 'string' && isEnumerable(O, k) ? [[k, O[k]]] : []);
      }, []);
    };
  }
  return Object;
}();